<template>
  <a-spin style="margin-top : 2rem" tip="Loading...">
        <div class="spin-content">
        </div>
      </a-spin>
</template>

<script>
export default {

}
</script>

<style>

</style>